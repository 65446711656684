@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Import Space Grotesk for modern AI feel */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&family=JetBrains+Mono:wght@400;500;600&display=swap');

@font-face {
  font-family: 'Gambarino';
  src: url('../../public/fonts/Gambarino-Regular.eot') format('woff2'),
  url('../../public/fonts/Gambarino-Regular.woff') format('woff'),
  url('../../public/fonts/Gambarino-Regular.ttf') format('truetype'),
  url('../../public/fonts/Gambarino-Regular.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;

  font-display: swap;
}

/* Base font styles */
html {
  font-family: 'Space Grotesk', system-ui, -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Add this to hide the native cursor */
.cursor-none {
  cursor: none !important;
}

/* Make sure all clickable elements show the pointer cursor */
.cursor-none a, 
.cursor-none button,
.cursor-none input,
.cursor-none [role="button"] {
  cursor: none !important;
}

/* Variable font support */
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Space Grotesk', system-ui, -apple-system, sans-serif;
  }
}

/* Apply monospace font to code blocks */
code, pre {
  font-family: 'JetBrains Mono', monospace;
}

/* Canvas Editor Styles */
.bg-dark-grid {
  background-image: 
    linear-gradient(to right, rgba(35, 35, 35, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(35, 35, 35, 0.1) 1px, transparent 1px);
  background-size: 4rem 4rem;
  mask-image: radial-gradient(ellipse 60% 50% at 50% 50%, #000 70%, transparent 100%);
}

.bg-light-grid {
  background-image: 
    linear-gradient(to right, rgba(59, 130, 246, 0.05) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(59, 130, 246, 0.05) 1px, transparent 1px);
  background-size: 4rem 4rem;
  mask-image: radial-gradient(ellipse 60% 50% at 50% 50%, #fff 70%, transparent 100%);
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(45deg);
  }
  50% {
    transform: translateY(-20px) rotate(45deg);
  }
}

.animate-float {
  animation: float linear infinite;
}

/* Add to your global CSS file */
@media (max-width: 768px) {
  body {
    overflow-x: hidden;
    width: 100%;
    position: relative;
  }
}

@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}



.animate-ticker {
  animation: ticker 140s linear infinite;
  display: inline-block;
}


.ticker-wrapper {
  width: max-content;
  padding-right: 100%;
}

@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Only move half way since we have a duplicate */
  }
}

.ticker-track {
  animation: ticker 60s linear infinite;
  width: fit-content;
}

.ticker-container {
  mask-image: linear-gradient(to right, transparent, black 10px, black 90%, transparent);
}

.ticker-content {
  flex-shrink: 0;
  padding-right: 20px;
}

.container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.inputForm {
  display: flex;
  margin-bottom: 20px;
}

.promptInput {
  flex: 1;
  padding: 15px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px 0 0 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.submitButton {
  padding: 15px 30px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 0 8px 8px 0;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submitButton:hover {
  background-color: #0055aa;
}

.submitButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.suggestionsContainer {
  margin-top: 20px;
}

.suggestionsTitle {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.suggestionsList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.suggestionButton {
  padding: 8px 16px;
  background-color: #f0f2f5;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.suggestionButton:hover {
  background-color: #e0e3e8;
  transform: translateY(-1px);
}

.loadingIndicator {
  color: #888;
  font-style: italic;
  padding: 10px 0;
}


.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  max-width: 500px;
  width: 90%;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #777;
}

.benefitsList {
  margin: 20px 0;
  padding-left: 20px;
}

.benefitsList li {
  margin-bottom: 8px;
  position: relative;
  padding-left: 5px;
}

.promptPreview {
  margin: 25px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 6px;
}

.promptBox {
  font-style: italic;
  color: #555;
  margin-top: 8px;
  word-break: break-word;
}

.actionButtons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.signupButton, .pricingButton {
  padding: 12px 24px;
  border-radius: 6px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  transition: all 0.2s;
}

.signupButton {
  background-color: #0066cc;
  color: white;
  flex: 2;
}

.signupButton:hover {
  background-color: #0055aa;
}

.pricingButton {
  background-color: transparent;
  border: 1px solid #0066cc;
  color: #0066cc;
  flex: 1;
}

.pricingButton:hover {
  background-color: #f0f7ff;
}


/* Add this to your existing CSS */

/* Safari-specific fixes for background animations */
@supports (-webkit-touch-callout: none) {
  /* Target Safari specifically */
  .animated-line,
  .ticker-track,
  svg path,
  svg line {
    /* Force hardware acceleration and prevent flickering */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000px;
  }
  
  /* Improve animation performance */
  .animate-ticker,
  .ticker-track {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  /* Fix for gradient animations that might flicker */
  [class*="from-"],
  [class*="via-"],
  [class*="to-"],
  [class*="bg-gradient"] {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }
}

/* Add this to your global CSS */
#search-dropdown-portal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  z-index: 9999;
  pointer-events: none;
}

#search-dropdown-portal > * {
  pointer-events: auto;
}

/* Custom text selection colors */
::selection {
  background-color: rgba(59, 130, 246, 0.3);
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

/* Remove default focus outline and replace with custom styles */
*:focus {
  outline: none !important;
}

/* Custom appearance for input fields */
input:focus {
  box-shadow: none !important;
  border-color: transparent !important;
}

/* Subtle hover transform for interactive elements */
.hover-lift {
  transition: transform 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.hover-lift:hover {
  transform: translateY(-2px);
}

/* Subtle scale animation for card elements */
@keyframes subtle-pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.01); }
  100% { transform: scale(1); }
}

.pulse-on-hover:hover {
  animation: subtle-pulse 2s infinite ease-in-out;
}
